import React, {useEffect} from 'react';
import {graphql, Link, navigate, useStaticQuery} from "gatsby";
import {Blog} from "../../interfaces/Blog";
import Layout from "../Layout";
import './styles.scss';
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import SEO from "../SEO";
import {supported_languages} from '../../helpers/values.json';

const getRedirectLanguage = () => {
    if (typeof navigator === `undefined`) {
        return "en";
    }
    const lang = navigator && navigator.language && navigator.language.split("-")[0];
    if (!lang) return "en";
    return lang;
};
export default function Blogs(props: any) {
    useEffect(()=>{
        const urlLang = getRedirectLanguage();
        if(urlLang!==props.pageContext.lang&&supported_languages.includes(urlLang)) navigate(`/${urlLang}/`, {replace: true});
    },[])
    let blogs:Blog[] = props.pageContext.blogs.map((b:Blog)=>{
      const compiledFeaturedImage=getImage(b.featuredImage.localFile);
      const compiledPicture=getImage(b.author.picture.localFile);
      return {...b,compiledFeaturedImage,author:{...b.author,compiledPicture}};
    });

    return (
        <Layout lang={props.pageContext.lang} locale={props.pageContext.locale}>
            <SEO title="Blogs | GED TEST ONLINE" blog={false}/>
            <div className="blogsList">
                <div className="heading">
                    {props.pageContext.locale["BlogsList.Head.title"]}
                </div>
                <div className="desc">
                    {props.pageContext.locale["BlogsList.Head.desc"]}
                </div>
                <div className="blogs">
                    {blogs.map((blog) => <Link to={(props.pageContext.lang==="en"?"":`/${props.pageContext.lang}`)+"/blog/"+blog.slug} ><div key={blog.title} className="blog">
                        <div className="image">{blog.compiledFeaturedImage&&<div><GatsbyImage alt={blog.title} image={blog.compiledFeaturedImage}/></div>}</div>
                        <span className="tag">{blog.tag}</span>
                        <div className="title">{blog.title}</div>
                        <div className="info">{blog.publishedDate||blog.publishedAt} • {blog.minToRead} min{blog.minToRead > 1 && 's'} read
                        </div>
                        <div className="content">{blog.description}</div>
                        <div className="author">
                            <div className="dp">{blog.author.compiledPicture&&<GatsbyImage alt={blog.author.name} image={blog.author.compiledPicture}/>}</div>
                            <div className="name">{blog.author.name}</div>
                        </div>
                    </div></Link>)}
                </div>
            </div>

        </Layout>);
}

